.container_bottombar {
  display: flex;
  position: fixed;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  background-color: rgba(45, 45, 45, 0.9);
  box-sizing: border-box;
  left: 2vw;
  right: 2vw;
  bottom: -1px;
  height: auto;
  z-index: 100;
  transition: all ease-in-out 0.4s;
  margin: 0;
  border-radius: 17px;
}
.gruppo_icone {
  display: flex;
  flex-direction: row;
  width: auto;
  height: auto;
  align-items: center;
  justify-content: center;
}
.gruppo_left {
  margin-left: 34px;
}
.menu-bottombar {
  display: flex;
  flex-direction: row;
  font-size: 0.9em;
  margin: auto 5px;
  padding: 5px;
  align-content: center;
  justify-content: center;
  color: #ffffff;
  text-decoration: none;
  width: auto;
}
.menu-bottombar label {
  display: flex;
  flex-direction: row;
  width: auto;
  height: 25px;
  align-items: center;
  justify-content: center;
}
.menu-bottombar span {
  margin: 0 auto;
}

@media all and (max-width: 768px) {
  .container-bottombar{
    left: 2vw;
    right: 2vw;
  }
  .menu-bottombar {
    flex-direction: row;
    font-size: 0.8em;
    margin: auto 0;
    justify-content: center;
    margin: 12px auto;
  }
  .menu-bottombar label {
    font-size: 13px;
    text-align: center;
  }
  .horizontal {
    flex-direction: row;
  }
}
