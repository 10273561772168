.prodotti {
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: auto;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  padding: 5px 0 40px 0;
  box-sizing: border-box;
}
.margin50{
  margin-top: 50px;
}
.search {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: flex-start;
  margin-top: 40px;

  justify-content: center;
}
@media all and (max-width: 768px) {
  .prodotti {
    padding: 5px 10px 20px 10px;
    margin-top: 20px;
  }

}
