.modal-uscite-container {
  background-color: rgba(0, 0, 0, 0.8);
}
.uscite {
  display: flex;
  flex-direction: column;
  width: 90%;
  height: 90%;
  align-items: center;
  justify-content: center;
  margin: auto;
}
.uscita {
  display: flex;
  color: white;
  margin: 10px;
  flex-direction: row;
  width: 75%;
  height: auto;
  align-items: center;
  justify-content: center;
  margin: 5px auto;
}
.container-infomodal .titolo {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  font-size: 24px;
  justify-content: center;
}
.black {
  color: rgb(46, 46, 46);
}
.pin_verifica_container{
  display: flex;
  flex-direction: column;
  width: 50%;
  height: auto;
  margin: auto;
}

@media screen and (max-width: 768px) {
  .container-infomodal{
    width: 95%;
    height: 80%;
  }
  .uscite{
    width: 95%;
  }
  .pin_verifica_container{
    width: 70%;
  }
}