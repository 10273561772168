.modal-container {
  display: flex;
  flex-direction: column;
  left: 0;
  right: 0;
  top: 30px;
  bottom: 0;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.8);
}
.container-infomodal {
  display: flex;
  flex-direction: column;
  width: 75%;
  height: 75%;
  overflow-y: scroll;
  min-height: 300px;
  min-width: 300px;
  margin: 10px auto;
  position: relative;
  border-radius: 5px;
  background-color: #ffffff;
}
.menu-close {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 31px;
  height: 31px;
  align-items: center;
  justify-content: center;
  top: 3px;
  right: 3px;
  background-color: #a00505;
  border: 2px solid #6f0101;
  color: #ffffff;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
}
.menu-close .material-symbols-outlined {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #a00505;
  margin: auto;
  justify-content: center;
  align-items: center;
}
.nome-prodotto {
  display: flex;
  flex-direction: row;
  width: 80%;
  height: auto;
  margin: 10px auto;
  align-items: center;
  justify-content: flex-start;
  font-size: 30px;
}
.dati-prodotto {
  display: flex;
  flex-direction: column;
  width: 96%;
  height: 80%;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0 auto;
}
.container-infomodal .pulsanti {
  width: 50%;
  margin: 10px auto;
}
.dati-prodotto .ingredienti-modal {
  display: block;
  width: 90%;
  height: auto;
  margin: 15px auto;
  word-wrap: break-word;
  line-height: 1.6rem;
  font-size: 1.3rem;
}
.dati-prodotto .eng {
  font-size: 20px;
  font-style: italic;
}
.testo-privacy {
  display: flex;
  flex-direction: column;
  width: 70%;
  height: 80%;
  overflow-y: scroll;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  margin: auto;
  line-height: 1.5em;
}
.conting {
  display: flex;
  flex-direction: row;
  width: 80%;
  height: auto;
  align-items: flex-start;
  justify-content: center;
  margin: 5px auto;
}
.sx {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: auto;
  padding: 5px;
  box-sizing: border-box;
}
.dx {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: auto;
  padding: 5px;
  box-sizing: border-box;
}
.dx span {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  align-items: flex-start;
  justify-content: flex-start;
  font-variant: small-caps;
  background-color: #e6e6e6;
}
.prod_note {
  display: flex;
  flex-direction: column;
  width: 80%;
  height: auto;
  margin: 0 auto;
  border: none;
  border-bottom: 1px solid#cdcdcd;
  padding: 7px;
  box-sizing: border-box;
}
.area_note {
  display: flex;
  flex-direction: column;
  width: 80%;
  height: auto;
  margin: 0 auto;
}
.area_note textarea {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 120px;
  resize: none;
  align-items: flex-start;
  justify-content: flex-start;
  overflow-y: scroll;
}
.area_note .field__label {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  margin: 5px;
  box-sizing: border-box;
  font-variant: small-caps;
}

@media screen and (max-width: 600px) {
  .modal-container {
    left: 0;
    top: 50px;
    box-sizing: border-box;
  }
  .container-infomodal {
    height: 95%;
    margin: 10px auto;
  }
  .dati-prodotto .img-prodotto {
    width: 90%;
    max-width: 400px;
  }
  .dati-prodotto .img-prodotto img {
    width: 100%;
    min-width: 400px;
    object-fit: cover;
  }
}
